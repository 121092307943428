import React from "react";
import PropTypes from "prop-types";

function NextArrow(props) {
  const { className, style, onClick, label } = props;
  return (
    <button
      type="button"
      data-role="none"
      className={className}
      style={{ ...style, display: "block" }}
      onClick={onClick}>
      {label} Next
    </button>
  );
}

NextArrow.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
};
NextArrow.defaultProps = {
  label: null,
  className: "",
  style: null,
  onClick: () => {},
};

NextArrow.displayName = "NextArrow";

export default NextArrow;
